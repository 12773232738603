import React, { useState } from 'react'
import { Portal } from 'react-portal'
import { useResponsive } from './responsiveStore'

export function ModalButton(props) {
  let {
    title,
    info,
    description,
    icon,
    children,
    button,
    classes = [],
    buttonClasses = ['button', 'button--small'],
    buttonIcon = false,
    size,
    defaultOpen = false,
  } = props

  let [open, setOpen] = useState(defaultOpen)

  let inner = typeof children === 'function' ? children({ closeModal: () => setOpen(false) }) : children

  return (
    <div className={classes.join(' ')}>
      <button className={buttonClasses.join(' ')} onClick={e => e.preventDefault() & setOpen(true)}>
        {buttonIcon && <i className={`fa fa-${buttonIcon}`} />}
        {button}
      </button>
      {open && (
        <Modal size={size}>
          <ModalHeader title={title} icon={icon} description={info || description} onClose={() => setOpen(false)} />
          <ModalContent>{inner}</ModalContent>
        </Modal>
      )}
    </div>
  )
}

export function ModalHeader({ title = '', description = '', icon = '', titleIcon = '', onClose }) {
  const renderTitle = titleString => (
    <div className="modal-header-title">
      {titleIcon ? <i className={`fa fa-${titleIcon}`} /> : null} {titleString}
    </div>
  )
  return (
    <div className="modal-header">
      {title ? renderTitle(title) : null}
      {description ? (
        <div className="modal-header-info">
          {icon ? <i className={`fa fa-${icon}`} /> : null}
          {description}
        </div>
      ) : null}
      {typeof onClose === 'function' ? (
        <button className="modal-header-close" onClick={e => e.preventDefault() & onClose()} data-testid="close-button">
          <i className="fa fa-times" />
        </button>
      ) : null}
    </div>
  )
}

export function ModalContent(props) {
  const { children, className } = props
  const classNames = ['modal-content']
  if (className) classNames.push(className)
  return <div className={classNames.join(' ')}>{children}</div>
}

export function Modal(props) {
  let { breakpointClasses } = useResponsive()

  let { children, size = 'medium', id = 'default', externalClasses = [] } = props

  let classes = [...externalClasses, 'modal', 'modal--responsive', `modal--${size}`, `modal--${id}`]

  return (
    <Portal>
      <div className={breakpointClasses.map(s => `breakpoint-${s}`).join(' ')}>
        <div className={classes.join(' ')}>
          <div className="modal-inner">{children}</div>
        </div>
      </div>
    </Portal>
  )
}
