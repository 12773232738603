import React, { lazy, Suspense } from 'react'
import { Route, Switch } from 'react-router-dom'
import { ApiProvider } from './api'
import { WebsocketContextProvider } from './api/websocket/context'
import FieldsProvider from './appField/fieldStore'
import { AuthProvider } from './auth/authStore'
import { BillingPlanInfoContextProvider } from './billing/hook'
import { BillingContextProvider } from './billing/hook/BillingContextProvider'
import { BillingPlanContextProvider } from './billing/hook/BillingPlanContextProvider'
import { VATInfoProvider } from './billing/VATInfoProvider'
import PageLoader from './common/pageLoader'
import { ResponsiveProvider } from './common/responsiveStore'
import Meta from './meta'
import TagProvider from './tag/tagStore'

const Login = lazy(() => import('./auth/login-page/login-page'))
const Register = lazy(() => import('./auth/register'))
const VerifyEmail = lazy(() => import('./auth/verify-email'))
const Token = lazy(() => import('./auth/token'))
const SsoRedirect = lazy(() => import('./auth/sso/page'))
const Logout = lazy(() => import('./auth/logout'))

const Unsubscribe = lazy(() => import('./unsubscribe/unsubscribe'))
const Notification = lazy(() => import('./unsubscribe/notification'))
const Dashboard = lazy(() => import('./dashboard/dashboardRoutes'))
const EmailVerification = lazy(() => import('./send/emailVerification'))
const PublicReport = lazy(() => import('./features/report/public'))
const Onboard = lazy(() => import('./onboard'))

const BlackWeek = lazy(() => import('./billing/offer/black-week'))

const providers = [
  ResponsiveProvider,
  ApiProvider,
  AuthProvider,
  TagProvider,
  FieldsProvider,
  WebsocketContextProvider,
  BillingPlanContextProvider,
  BillingContextProvider,
  BillingPlanInfoContextProvider,
  VATInfoProvider,
]

const app = (
  <Suspense fallback={<PageLoader />}>
    <Meta />
    <Switch>
      <Route path="/blackweek" component={BlackWeek} />
      <Route path="/login" component={Login} />
      <Route path="/login/token" component={Login} />
      <Route path="/register" component={Register} />
      <Route path="/verify-email" component={VerifyEmail} />
      <Route path="/sso/:service" component={SsoRedirect} />
      <Route path="/token" component={Token} />
      <Route path="/logout" component={Logout} />
      <Route path="/unsubscribe" component={Unsubscribe} />
      <Route path="/notification" component={Notification} />
      <Route path="/onboard" component={Onboard} />
      <Route path="/email-verification/:status" component={EmailVerification} />
      <Route path="/r/:report_id" component={PublicReport} />
      <Route path="/" component={Dashboard} />
    </Switch>
  </Suspense>
)

function connectProviders(providers, children) {
  let [provider, ...rest] = providers
  return React.createElement(provider, null, rest.length ? connectProviders(rest, children) : children)
}

export default connectProviders(providers, app)
