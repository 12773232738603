import { TranslationProvider as UITranslationProvider } from '@trustmary/ui/i18n/TranslationProvider'
import axios from 'axios'
import moment from 'moment'
import { ReactNode, useCallback, useEffect, useState } from 'react'
import PageLoader from '../common/pageLoader'
import { track } from '../track'
import localEn from '../translations/en.json'
import localFi from '../translations/fi.json'
import { TranslationContext, TranslationContextType, Translator } from './translationContext'

const TRANSLATIONS_URL = 'https://d3ingpyowh9rb.cloudfront.net/translations.json'

/* This is a quick fix for random CORS errors */
const getTranslationsUrl = () => {
  if (window.location.hostname === 'app.trustmary.com') return '/translations.json'
  return TRANSLATIONS_URL
}

interface Translation {
  [key: string]: string
}

interface Translations {
  [language: string]: Translation[]
}

const defaultLang = 'en'

async function loadTranslations(): Promise<Translations> {
  let { data } = await axios.get<Translations>(getTranslationsUrl())
  data = data || {}
  data.fi = Object.assign(data.fi, localFi)
  data.en = Object.assign(data.en, localEn)
  return data
}

const urlQuery: { [key: string]: string } = window.location.search
  .substr(1)
  .split('&')
  .reduce((mapped, str) => {
    const [key, value] = str.split('=')
    mapped[key] = value
    return mapped
  }, {})

function getBrowserLang(): string | undefined {
  if (!navigator.language) return
  const [browserLang] = navigator.language.split('-')
  return appLanguages[browserLang] ? browserLang : undefined
}

const locale = window.navigator.language

export function TranslationProvider({ children }: { children?: ReactNode }): JSX.Element {
  const [translations, setTranslations] = useState<Translations>()
  const [initError, setInitError] = useState<Error>()

  /** Language setter */
  const [lang, _setLanguage] = useState<string>(() => urlQuery['lang'] || getBrowserLang() || 'en')

  /** Event tracking wrapper for setLanguage()  */
  const setLanguage = useCallback((lang: string) => {
    track('SETTINGS_SET_LANGUAGE', { app_lang: lang })
    _setLanguage(lang)
  }, [])

  useEffect(() => {
    void (async () => {
      moment.locale(locale)
      try {
        const translations = await loadTranslations()
        setTranslations(translations)
      } catch (error) {
        // Set up error for passing upwards
        setInitError(error as Error)
      }
    })()
  }, [])

  /** Pass the asynchronously rejected error upwards */
  useEffect(() => {
    if (initError) throw initError
  }, [initError])

  useEffect(() => {
    localStorage._lang = lang
  }, [lang])

  const hasTranslation = useCallback(
    (key: string): boolean => {
      return typeof translations?.[lang]?.[key] === 'string'
    },
    [lang, translations]
  )

  const t = useCallback<Translator>(
    (key, replacements, overrideLang): string => {
      if (!translations) return ''
      const l = overrideLang || lang
      let value = (translations[l]?.[key] as string) || false

      value = value || (translations[defaultLang][key] as string)

      if (!value) {
        const message = `Missing translation key: ${key}`
        console.error(message)

        // TODO: Enable this when obvious missing translations are fixed
        // Disabled to save Sentry quota
        // Sentry.captureMessage(message)

        value = key
      }

      if (Array.isArray(replacements) && replacements.length)
        value = replacements.reduce(
          (value, replacement, key) => value.replace(new RegExp(`:${key}`, 'g'), replacement),
          value
        )

      return value
    },
    [lang, translations]
  )

  const contextValue: TranslationContextType = {
    t,
    hasTranslation,
    setLanguage,
    lang,
    languages,
    appLanguages,
    brand: 'trustmary',
    locale,
  }

  if (!translations) return <PageLoader />

  return (
    <TranslationContext.Provider value={contextValue}>
      {/* UI has simple t function to make composites more reusable */}
      <UITranslationProvider t={t}>{children}</UITranslationProvider>
    </TranslationContext.Provider>
  )
}

export const languages: { [key: string]: string } = {
  fi: 'Suomeksi',
  en: 'In English',
  se: 'På Svenska',
  fr: 'En Français',
  es: 'En Español',
  de: 'Auf Deutsch',
}

export const appLanguages: { [key: string]: string } = {
  fi: 'Suomeksi',
  en: 'In English',
  es: 'Español',
  fr: 'Français',
  de: 'Deutsch',
  sv: 'Svenska',
  nl: 'Nederlandse taal',
}
