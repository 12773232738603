import { fromJS, Map } from 'immutable'
import React from 'react'
import CheckboxField from './checkbox'
import CheckboxConfirmField from './checkboxConfirm'
import CheckboxesField from './checkboxes'
import CodeEditorField from './codeEditor'
import ColorPicker from './colorPicker'
import ComponentField from './component'
import ConditionsField from './conditions'
import ContentField from './content'
import CountryField from './country'
import DatetimeField from './datetime'
import EmbedThemeField from './embedTheme'
import { default as File, default as FileField } from './file'
import FormStore from './formStore'
import IconSelectField from './iconSelect'
import InputField from './input'
import LinksField from './Links'
import MultiSelectField from './multiselect'
import OptionsField from './options'
import RadiosField from './radios'
import RepeaterField from './repeater'
import SelectField from './select'
import TemplatesField from './templates'
import TimeField from './time'
import TimeofdayField from './timeofday'
import WysiwgField from './wysiwyg'

let inputComponents = {
  textfield: props => <InputField {...props} type="text" />,
  text: props => <InputField {...props} type="text" />,
  email: props => <InputField {...props} type="email" />,
  number: props => <InputField {...props} type="number" />,
  password: props => <InputField {...props} type="password" />,
  hidden: props => <InputField {...props} type="hidden" />,
  radios: RadiosField,
  checkboxes: CheckboxesField,
  checkbox: CheckboxField,
  checkboxConfirm: CheckboxConfirmField,
  options: OptionsField,
  select: SelectField,
  links: LinksField,
  wysiwyg: WysiwgField,
  country: CountryField,
  color: ColorPicker,
  colorPicker: ColorPicker,
  conditions: ConditionsField,
  multiselect: MultiSelectField,
  code: CodeEditorField,
  iconSelect: IconSelectField,
  templates: TemplatesField,
  embedTheme: EmbedThemeField,
  content: ContentField,
  file: FileField,
  component: ComponentField,
  repeater: RepeaterField,
  time: TimeField,
  image: props => <File {...props} fileType=".jpg,.jpeg,.png,.gif" />,
  video: props => <File {...props} fileType=".mp4,.mov,.webm" maxSize={262144000} />,
  file: props => <File {...props} />,
  textarea: props => <InputField {...props} type="textarea" />,
  datetime: DatetimeField,
  timeofday: TimeofdayField,
}

function FormField(props) {
  let {
    name,
    type,
    label,
    description,
    inputLabel = false,
    formState,
    formUpdate,
    formFileUpdate,
    formFileRemove,
    placeholder,
    prefix,
    suffix,
    processValue = false,
    processContext = {},
    options = [],
    simple = false,
    t,
    disabled = false,
    lang,
    mainLang,
    min,
    max,
    style = [],
    hideWhen = false,
    after = false,
    before = false,
    ...restprops
  } = props

  let isHidden = typeof hideWhen === 'function' ? hideWhen.call(null, formState) : false
  if (isHidden) return null

  let { values, errors, translate } = formState

  // If formState has translate flag, run label and description through t-function
  if (label && translate) label = t(label)

  if (description && translate) description = t(description)

  if (typeof options === 'function') options = options.call(null, formState)

  if (Array.isArray(options) && translate)
    options = options.map(o => {
      if (o.label) o.label = t(o.label)

      if (o.description) o.description = t(o.description)

      return o
    })

  if (Map.isMap(label)) label = label.get('default')

  let input = inputComponents[type] || false

  let hasError = errors[name] !== undefined
  let errorElements = hasError
    ? errors[name].map((text, key) => (
        <div className="form-item-error" key={key}>
          {text}
        </div>
      ))
    : []

  let onChange =
    typeof processValue !== 'function' ? formUpdate : e => processValue(e, formUpdate, formState, processContext)

  let inputProps = {
    name,
    inputLabel,
    onChange,
    onFileChange: formFileUpdate,
    onFileRemove: formFileRemove,
    value: values.getIn(name.split('.'), ''),
    values,
    placeholder,
    prefix,
    suffix,
    options,
    error: hasError,
    simple,
    t,
    disabled,
    lang,
    mainLang,
    min,
    max,
    translate,
    style,
    ...restprops,
  }

  let classes = ['form-item', `form-item--${type}`]
  if (errorElements.length) classes.push('form-item--error')

  if (label) classes.push('form-item--label')

  if (simple) classes.push('form-item--simple')

  if (style.length) classes = classes.concat(style.map(style => `form-item-style--${style}`))

  return (
    <div className={classes.join(' ')}>
      {before ? <div className="form-item-before">{before}</div> : null}
      {label ? (
        <label className="form-item-label" htmlFor={name}>
          <span>{label}</span>
        </label>
      ) : null}
      <div className={`form-item-input form-item-input--${type}`}>
        {input ? React.createElement(input, inputProps) : `Input type ${type} not found`}
        {after ? <div className="form-item-after">{after}</div> : null}
      </div>
      {description ? <div className="form-item-description">{description}</div> : null}
      {errorElements.length ? <div className="form-item-errors">{errorElements}</div> : null}
    </div>
  )
}

export default function FormFieldWrapper(props) {
  // Note: t is passed separately so that formState.t (which might be unmocked in a test suite) doesn't overwrite props.t
  return <FormStore>{formState => <FormField {...props} {...formState} t={props.t || formState.t} />}</FormStore>
}
