// Force build counter: 6
import React from 'react'
import ReactDOM from 'react-dom'
import { App } from './AppComponent'
import './sass/app.sass'
import './sentry'
import 'reactflow/dist/style.css'

const render = () => {
  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById('app')
  )
}

// Note: We now assume Promise is natively supported and that we do not need to polyfill it

if (
  'Map' in window &&
  'forEach' in NodeList.prototype &&
  'startsWith' in String.prototype &&
  'endsWith' in String.prototype &&
  'includes' in String.prototype &&
  'includes' in Array.prototype &&
  'assign' in Object &&
  'entries' in Object &&
  'keys' in Object
) {
  render()
} else {
  void import('./polyfills').then(render)
}
