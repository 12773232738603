import { SnsMessage } from '@tm/types/event/sns'
import { useCallback, useContext, useEffect } from 'react'
import { WebsocketContext } from './context'

export const useWebsocketEvent = (eventName: string, callback: (messageBody: SnsMessage) => void) => {
  const { websocket } = useContext(WebsocketContext)

  const handleWebsocketEvent = useCallback(
    (event: MessageEvent) => {
      try {
        const messageBody = JSON.parse(event.data as string) as SnsMessage
        if (messageBody.topic === eventName) callback(messageBody)
      } catch (e) {
        console.log(e)
      }
    },
    [callback, eventName]
  )

  useEffect(() => {
    if (websocket) {
      websocket.addEventListener('message', handleWebsocketEvent)
      return () => {
        websocket.removeEventListener('message', handleWebsocketEvent)
      }
    }
  }, [handleWebsocketEvent, websocket])
}
